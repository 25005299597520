/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    #root {
        height: 100%;
    }
    
    .App {
        height: 100%;
        overflow-x: hidden;
    }
}