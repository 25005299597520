/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #root {
        height: 100%;
    }
    
    .App {
        height: 100%;
    }
}

