/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    #root {
        height: 100%;
    }
    
    .App {
        height: 100%;
        overflow-x: hidden;
    }
}
