/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    #root {
        height: 100%;
    }
    
    .App {
        height: 100%;
    }
}

